/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Container } from "../layout/Container";
import { SocialBar } from "../home/SocialBar";
import { isHmc } from "../../utils/isHmc";
import { HighlightedRoom } from "../room/HighlightedRoom";
import { cleanDescription, getArtistName, getPosition, getVideoUrl } from "../../utils/highlightedRooms";
import { ContactMeArea } from "./ContactMeArea";
import qsTruthy, { qsGet } from "../../utils/qs_truthy";
import { MailchimpFormContainer } from "./subscribe-area/MailchimpFormContainer";
import { Faqs } from "./Faqs";
import { Button } from "../input/Button";
import { getCookieToken } from "../../utils/get-auth-user";

const isVideo = url => url && url.match(/\.(mp4|webm)$/);

export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();
  const store = window.APP.store;

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const token = getCookieToken();
  const apiUrl = configs.CUSTOM_API_URL;
  const folder = `assets360/${configs.APP_NAME}/my-media`;

  const [isArtist, setIsArtist] = useState(false);

  const redirectAfterLoginUrl = qsGet("redirect") || localStorage.getItem("redirectAfterLoginUrl");

  if (redirectAfterLoginUrl) {
    localStorage.removeItem("redirectAfterLoginUrl");
    window.location = redirectAfterLoginUrl;

    return (
      <>
        <br />
      </>
    );
  }

  if (
    !qsTruthy("admin") &&
    !redirectAfterLoginUrl &&
    ["eventfy", "mud foundation", "next reality digital", "onland", "gocity"].includes(
      configs.APP_NAME.toLowerCase()
    ) &&
    configs.NODE_ENV !== "development"
  ) {
    const redirectUrl = configs.CUSTOM_HOME_PAGE_URL || configs.CUSTOM_API_HOST;
    window.location = redirectUrl;

    return <></>;
  }

  if (qsTruthy("auth")) {
    return <></>;
  }

  useEffect(() => {
    setIsArtist(store.state.authUser?.auth0_permissions?.includes("artist"));
  }, [store.state.authUser]);

  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }
  }, []);

  const isLoop = configs.APP_NAME === "Loop";
  const showMailChimp = isLoop;
  const showFaqs = isLoop;

  const sortedFavoriteRooms = Array.from(favoriteRooms)
    .map(room => ({
      ...room,
      description: room.description && cleanDescription(room.description),
      position: getPosition(room.description) || 9999,
      artist: getArtistName(room.description)
    }))
    .sort((a, b) => b.member_count - a.member_count);

  const sortedPublicRooms = Array.from(publicRooms)
    .map(room => ({
      ...room,
      description: room.description && cleanDescription(room.description),
      position: getPosition(room.description) || 9999,
      artist: getArtistName(room.description)
    }))
    .sort((a, b) => a.position - b.position);

  const highlightedPublicRooms = publicRooms
    .filter(r => r.description && r.description.toLowerCase().includes("#highlight"))
    .map(room => ({
      ...room,
      description: room.description && cleanDescription(room.description),
      video: room.description && getVideoUrl(room.description),
      position: getPosition(room.description) || 9999,
      artist: getArtistName(room.description)
    }))
    .sort((a, b) => a.position - b.position);

  const wrapInBold = chunk => <b>{chunk}</b>;

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;

  return (
    <PageContainer className={styles.homePage}>
      <div className={styles.hero}>
        <div className={styles.appInfo}>
          <div className={styles.appDescription}>{configs.translation("app-tagline")}</div>
          <div className={styles.appSubDescription}>{configs.translation("app-description")}</div>
          {canCreateRooms && <CreateRoomButton />}
          <PWAButton />
        </div>
        <div className={styles.heroImageContainer}>
          {isVideo(configs.image("home_background")) ? (
            <video
              id="hero-cont"
              autoPlay
              muted
              loop
              playsInline
              className={styles.heroImage}
              poster={configs.image("app_thumbnail")}
            >
              <source src={configs.image("home_background")} />
            </video>
          ) : (
            <img
              alt={intl.formatMessage(
                {
                  id: "home-page.hero-image-alt",
                  defaultMessage: "Screenshot of {appName}"
                },
                { appName: configs.translation("app-name") }
              )}
              src={configs.image("home_background")}
            />
          )}
        </div>
      </div>
      {configs.feature("show_feature_panels") && (
        <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_rooms_thumb")} />
            <h3>
              <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.rooms-blurb"
                defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with {appName}, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
                values={{ b: wrapInBold, appName: configs.APP_NAME }}
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_communicate_thumb")} />
            <h3>
              <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.communicate-blurb"
                defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. {appName}
                      makes it easy to stay connected with voice and text chat to other people in your private room."
                values={{
                  appName: configs.APP_NAME
                }}
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_media_thumb")} />
            <h3>
              <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.media-blurb"
                defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
              />
            </p>
          </Column>
        </Container>
      )}
      {highlightedPublicRooms.length > 0 && (
        <>
          {highlightedPublicRooms.map((room, i) => {
            return <HighlightedRoom key={room.id} room={room} className={i === 0 ? "first" : ""} />;
          })}
        </>
      )}
      {sortedPublicRooms.length > 0 && (
        <div className={styles.roomsContainer}>
          <h3 className={styles.roomsHeading}>
            <FormattedMessage
              id="home-page.public--rooms"
              defaultMessage="{appName} Open Metaverse"
              values={{ appName: configs.translation("app-name") }}
            />
          </h3>
          <MediaGrid>
            {sortedPublicRooms.map(room => {
              return (
                <MediaTile
                  key={room.id}
                  entry={room}
                  processThumbnailUrl={(entry, width, height) =>
                    scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                  }
                />
              );
            })}
          </MediaGrid>
        </div>
      )}
      {sortedFavoriteRooms.length > 0 && (
        <div className={styles.roomsContainer}>
          <h3 className={styles.roomsHeading}>
            <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
          </h3>
          <MediaGrid>
            {sortedFavoriteRooms.map(room => {
              return (
                <MediaTile
                  key={room.id}
                  entry={room}
                  processThumbnailUrl={(entry, width, height) =>
                    scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                  }
                />
              );
            })}
          </MediaGrid>
        </div>
      )}

      {showMailChimp && <MailchimpFormContainer></MailchimpFormContainer>}
      {showFaqs && <Faqs />}

      <Container>
        <Column center grow>
          <Button preset="primary" as="a" href="/link">
            <FormattedMessage id="home-page.have-code" defaultMessage="Have a metaverse code?" />
          </Button>
        </Column>
      </Container>

      {configs.CONTACT_ME_EMAIL && <ContactMeArea email={configs.CONTACT_ME_EMAIL}></ContactMeArea>}

      {isHmc() ? (
        <Column center>
          <SocialBar />
        </Column>
      ) : null}
    </PageContainer>
  );
}
